import * as React from "react"
import { Link } from "gatsby"
import { Layout } from "../components/layout"
import { StoreContext } from "../context/store-context"
import { formatPrice } from "../utils/format-price"
import { Button, Col, Container, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap"
import ShoppingCartItem from "../components/ShoppingCartItem"
import { InfoCircle } from "react-bootstrap-icons"
import { shopifyVariantsToItems } from "../utils/gtag"
import { Seo } from "../components/seo"

export default function Winkelwagen() {
  const { checkout, loading } = React.useContext(StoreContext)
  const emptyCart = checkout.lineItems.length === 0

  const checkoutEvent = () => {
    if (!checkout || checkout.lineItems.length === 0) {
      return;
    }
    // Event tracking
    const dataItems = checkout.lineItems.flatMap(item => {
      const variant = item.variant;
      return shopifyVariantsToItems([{
        variant: variant,
        quantity: Math.abs(item.quantity),
      }])
    })
    const totalValue = dataItems.reduce((result, dataItem) => {
      return result + (dataItem.quantity * dataItem.price);
    }, 0);

    const checkoutEventData = {
      currency: checkout.lineItems[0].variant.priceV2.currencyCode,
      amount: totalValue,
      items: dataItems,
    }
    typeof window !== "undefined" && typeof window.gtag !== "undefined" && window.gtag("event", "begin_checkout", checkoutEventData)

  }

  const handleCheckout = () => {
    window.open(checkout.webUrl);
    try {
      checkoutEvent();
    } catch (e) {
      console.warn("Could not send analytics event", e);
    }
  }

  return (
    <Layout hideCartLink={true}>
      <Container className="pt-7 pb-4">
        {emptyCart ? (
          <div className="text-center">
            <h1 className="display-2 fw-bold text-center">Geen producten in winkelwagen</h1>
            <p>
              Je winkelwagen is leeg.
            </p>
            <Link className="btn btn-primary text-light" to="/producten/">Producten bekijken</Link>
          </div>
        ) : (
          <>
            <h1 className="display-2 fw-bold text-center">Je winkelwagen</h1>
            <Table responsive className="table-borderless bg-transparent">
              <thead>
                <tr>
                  <th>Afbeelding</th>
                  <th>Product</th>
                  <th>Prijs</th>
                  <th>Aantal</th>
                  <th>Totaal</th>
                </tr>
              </thead>
              <tbody>
                {checkout.lineItems.map((item) => (
                  <ShoppingCartItem item={item} key={item.id} />
                ))}

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>Subtotaal</td>
                  <td>
                    {formatPrice(
                      checkout.subtotalPriceV2.currencyCode,
                      checkout.subtotalPriceV2.amount
                    )}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    Verzendkosten
                    <OverlayTrigger
                      placement="bottom"
                      trigger={["click", "hover"]}
                      overlay={<Tooltip>Gratis verzending vanaf €35. Afhalen kan ook altijd.</Tooltip>}
                    >
                      <InfoCircle className="ms-1"/>
                    </OverlayTrigger>
                  </td>
                  <td>
                    {
                      checkout.subtotalPriceV2.amount >= 35
                        ? "Gratis"
                        : "€7,95"
                    }
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>Totaal</td>
                  <td>
                    {formatPrice(
                      checkout.totalPriceV2.currencyCode,
                      checkout.subtotalPriceV2.amount >= 35 ? checkout.totalPriceV2.amount : (parseInt(checkout.totalPriceV2.amount) + 7.95)
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
            <Row>
              <Col className="d-flex justify-content-end">
                <Button className="me-6" variant="success" onClick={handleCheckout} disabled={loading}>Naar de kassa</Button>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </Layout>
  )
}

export const Head = () => (
  <Seo
    title="Winkelwagen"
  />
)
