import * as React from "react"

import { Button, FormControl, InputGroup } from "react-bootstrap"
import { CaretDown, CaretUp } from "react-bootstrap-icons"
import classnames from "classnames"

export default function NumberInput({
  onIncrement,
  onDecrement,
  className,
  disabled,
  ...props
}) {
  return (
    <InputGroup>
      <Button
        disabled={disabled}
        aria-label="Decrement"
        onClick={onDecrement}
        size="sm"
        variant="outline-primary"
      >
        <CaretDown />
      </Button>
      <FormControl
        disabled={disabled}
        type="number"
        className={classnames(className, "border-primary")}
        {...props}
      />
      <Button
        disabled={disabled}
        aria-label="Increment"
        onClick={onIncrement}
        size="sm"
        variant="outline-primary"
      >
        <CaretUp />
      </Button>
    </InputGroup>
  )
}
