// Utils for GTag (google analytics) events

export function localVariantsToItems(variants) {
  return variants.map(({product, variant, quantity}) => {
    return {
      item_id: variant && variant.shopifyId,
      item_name: product && product.title,
      currency: product && product.priceRangeV2.minVariantPrice.currencyCode,
      item_category: (product && product.collections.length > 0) ? product.collections[0].title : undefined,
      item_variant: (variant && variant.selectedOptions.length > 0) ? variant.selectedOptions[0].value : undefined,
      price: variant && parseFloat(variant.price),
      quantity: quantity,
    }
  })
}

export function shopifyVariantsToItems(variants) {
  return variants.map(({variant, quantity}) => {
    return {
      item_id: variant && variant.id,
      currency: variant && variant.priceV2.currencyCode,
      item_variant: variant && variant.title,
      price: variant && parseFloat(variant.priceV2.amount),
      quantity: quantity,

    }
  })
}
