import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"

export default function useProductIdCollectionMap() {
  const collections = useStaticQuery(graphql`
    query {
      allShopifyCollection {
        nodes {
          ...CollectionCard
          products {
            id
            shopifyId
          }
        }
      }
    }
  `)?.allShopifyCollection?.nodes || [];

  const gatsbyProductIdCollectionsMap = React.useMemo(() => {
    return collections.reduce((result, collection) => {
      collection.products.forEach(product => {
        const productCollections = result.get(product.id) || [];
        productCollections.push(collection);
        result.set(product.id, productCollections);
      })
      return result;
    }, new Map());
  }, [collections]);

  const shopifyProductIdCollectionsMap = React.useMemo(() => {
    return collections.reduce((result, collection) => {
      collection.products.forEach(product => {
        const productCollections = result.get(product.shopifyId) || [];
        productCollections.push(collection);
        result.set(product.shopifyId, productCollections);
      })
      return result;
    }, new Map());
  }, [collections]);

  return {
    gatsbyProductIdCollectionsMap,
    shopifyProductIdCollectionsMap,
  }
}
